// RAMOS
export var listRamosGenerales = ["ACCIDENTES PERSONALES", "AP PROTECCION FINANCIERA", "ASIENTO DE PASAJEROS", "ASISTENCIA DE VIAJES", "AVERIA MAQUINARIA", "CASCO", "EQUIPO ELECTRONICO", "EQUIPO MOVIL", "GARANTIA", "INC. COMERCIAL & PYME", "INC. CONDOMINIO", "INC. HOGAR", "MISCELANEO", "RC - Responsabilidad Civil ", "RCI - Responsabilidad Civil Internacional", "RESPONSABILIDAD CIVIL D&O", "TRANSPORTE TERRESTRE", "TRC - Todo Riesgo Construcción ", "TRCM - Todo Riesgo Construcción y Montaje ", "VEH. LIVIANOS", "VEH. PESADOS", "SOAP"];
export var listRamosByCode = [{
  code: "VIDA",
  ramos: ["ACCIDENTES PERSONALES", "ACCIDENTES PERSONALES COLECTIVO", "APV", "CATASTRÓFICO DE SALUD", "COMPLEMENTARIO SALUD COLECTIVO", "COMPLEMENTARIO SALUD INDIVIDUA", "ONCOLÓGICO", "SALUD COLECTIVO", "VIDA Y AHORRO", "VIDA GUARDIAS", "VIDA INDIVIDUAL", "VIDA CONDUCTOR"]
}]; // CURRENCY

export var CurrencyTypes;

(function (CurrencyTypes) {
  CurrencyTypes["UF"] = "UF";
  CurrencyTypes["US"] = "US";
  CurrencyTypes["CLP"] = "CLP";
  CurrencyTypes["PERCENTAGE"] = "%";
})(CurrencyTypes || (CurrencyTypes = {}));

export var listCurrencies = Object.values(CurrencyTypes); // TIPO DE PAGO

export var TipoDePagoTypes;

(function (TipoDePagoTypes) {
  TipoDePagoTypes["PAC"] = "PAC";
  TipoDePagoTypes["PAT"] = "PAT";
  TipoDePagoTypes["AVISO_DE_VENCIMIENTO"] = "AVISO DE VENCIMIENTO";
  TipoDePagoTypes["CONTADO"] = "CONTADO";
  TipoDePagoTypes["ONE_CLICK"] = "ONE CLICK";
})(TipoDePagoTypes || (TipoDePagoTypes = {}));

export var listTipoDePago = Object.values(TipoDePagoTypes); // Number of Cuotes

export var listNumberOfCuotes = Array.from({
  length: 18
}, function (_, i) {
  return "".concat(i + 1);
}); // INFORMACION CORREDOR

export var AgentNames;

(function (AgentNames) {
  AgentNames["AgentName1"] = "Maria Loreto Gaete Celis 8.389.778-3";
  AgentNames["AgentName2"] = "Francisco Fern\xE1ndez 17.995.858-9";
  AgentNames["AgentName3"] = "Israel Pablo Bellin Molinaro 48.160.922-4";
  AgentNames["AgentName4"] = "Marcela Di Lascio Vergara 12.249.349-0";
  AgentNames["AgentName5"] = "Cristian Mike Contreras Opazo 17.147.660-7";
})(AgentNames || (AgentNames = {}));

;
export var listAgent = Object.values(AgentNames); // ORDENAR POR

export var sortByOptions = [{
  label: "Compañia (A - Z)",
  value: "company_name_asc"
}, {
  label: "Compañia (Z - A)",
  value: "company_name_desc"
}, {
  label: "Inicio Vigencia - Más antigua",
  value: "start_date_asc"
}, {
  label: "Inicio Vigencia - Más reciente",
  value: "start_date_desc"
}, {
  label: "Fin Vigencia - Más antigua",
  value: "finish_date_asc"
}, {
  label: "Fin Vigencia - Más reciente",
  value: "finish_date_desc"
}, {
  label: "Ramo (A - Z)",
  value: "ramo_asc"
}, {
  label: "Ramo (Z - A)",
  value: "ramo_desc"
}, {
  label: "Forma de Pago (A - Z)",
  value: "tipo_de_pago_asc"
}, {
  label: "Forma de Pago (Z - A)",
  value: "tipo_de_pago_desc"
}];